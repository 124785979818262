/* eslint-disable import/namespace */
import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  userIdsList: [],
  isSelectAll: false,
};

const approvalAssignmentSlice = createSlice({
  name: "approval assignment",
  initialState,
  reducers: {
    setUserIdList: (state, action) => {
      state.userIdsList = [...state.userIdsList, action?.payload];
    },
    removeFromUserIdList: (state, action) => {
      state.userIdsList = state.userIdsList?.filter((item) => {
        return item !== action?.payload;
      });
    },
    setSelectAll: (state, action) => {
      state.userIdsList = action?.payload?.map((item) => {
        return item?.userId;
      });
      state.isSelectAll = !state.isSelectAll;
    },
    removeSelectAll: (state) => {
      state.userIdsList = [];
      state.isSelectAll = !state.isSelectAll;
    },
    clearAllStates: (state) => {
      state.userIdsList = [];
      state.isSelectAll = false;
    },
  },
});
export const {
  setUserIdList,
  removeFromUserIdList,
  setSelectAll,
  removeSelectAll,
  clearAllStates,
} = approvalAssignmentSlice.actions;
export default approvalAssignmentSlice.reducer;
