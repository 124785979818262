
import React from 'react';
import { RouterProvider } from "react-router-dom";
import { routeObject } from "../../routes";
import { useSelector } from "react-redux";
import { ToastMessage } from "../toast-messages";
import { Suspense } from "react";
import { InitialLoadUi } from "../initial-site-load-loader";
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from '../../redux/store/store';

const App = () => {
  const { message, type } = useSelector((state) => state.toast);
   /* IT WILL GLOBALLY REMOVE CONSOLE LOGS, If doing development below line */
  // console.log = () => {};

  
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <>
          {message && <ToastMessage type={type} message={message} />}
          <Suspense fallback={<InitialLoadUi />}>
            <RouterProvider router={routeObject} />
          </Suspense>
        </>
      </PersistGate>
    </Provider>
  );
};

export default App;