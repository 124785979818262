import { createBrowserRouter } from "react-router-dom";
import { lazy } from "react";
import * as lazyLoad from "./lazyLoadFiles";

// Plan
const LazyPlan = lazy(() => import("../pages/plan"));
const LazyOrganizationProfile = lazy(() =>
  import("../pages/plan/organization-profile")
);
const LazyViewPlan = lazy(() => import("../pages/plan/admin-plan"));

// for super admin
const LazyPlanTabs = lazy(() =>
  import("../pages/plan/super-admin-plan/PlanTabsData")
);
const LazyOrganizationPlans = lazy(() =>
  import("../pages/plan/super-admin-plan/organization-plans")
);
const LazyPlanList = lazy(() =>
  import("../pages/plan/super-admin-plan/plan-list")
);

// Login
const LazyLandingPage = lazy(() => import("../pages/landing-page"));
const LazySignUp = lazy(() =>
  import("../pages/multi-tenant/organization/sign-up")
);
const LazySignIn = lazy(() => import("../pages/registration/sign-in"));
const LazyForgotPassword = lazy(() =>
  import("../pages/registration/forgot-password")
);
const LazyResetPassword = lazy(() =>
  import("../pages/registration/reset-password")
);
// const LazyChangePassword = lazy(() =>
//   import("../pages/registration/change-password")
// );
const LazyExpiredResetPasswordLink = lazy(() =>
  import("../pages/registration/reset-password/expired-link")
);

const LazyPageNotFound = lazy(() => import("../pages/page-not-found"));

const LazyAuthWrapper = lazy(() => import("../routes/AuthWrapper"));

// Main Layout
const LazyMainLayout = lazy(() => import("../pages/main-layout"));

// Roles and Permissions
const LazyRolesPermissions = lazy(() =>
  import("../pages/attendance-management/role-permission")
);
const LazyRolesPermissionsList = lazy(() =>
  import("../pages/attendance-management/role-permission/view-role-permission")
);
export const LazyAddRoles = lazy(() =>
  import("../pages/attendance-management/role-permission/add-role")
);

// Dashboard
const LazyDashboard = lazy(() =>
  import("../pages/attendance-management/dashboard")
);
const LazyApprovals = lazy(() =>
  import("../pages/attendance-management/approvals")
);
const LazyGetApprovals = lazy(() =>
  import("../pages/attendance-management/approvals/getApproval")
);
// HR Module
const LazyAttendanceManagement = lazy(() =>
  import("../pages/attendance-management")
);
const LazyEmployee = lazy(() =>
  import("../pages/attendance-management/employee")
);
const LazyGetEmployee = lazy(() =>
  import("../pages/attendance-management/employee/getemployee")
);

const LazyProfilePersonal = lazy(() =>
  import("../pages/attendance-management/view-profile/personal")
);
const LazyProfileJob = lazy(() =>
  import("../pages/attendance-management/view-profile/job")
);
const LazyProfileBank = lazy(() =>
  import("../pages/attendance-management/view-profile/bank")
);

const LazyProfilePersonalDetails = lazy(() =>
  import(
    "../pages/attendance-management/view-profile/employee-personal-history"
  )
);
const LazyProfileAppraisal = lazy(() =>
  import(
    "../pages/attendance-management/view-profile/employee-appraisal-history"
  )
);

const LazyEmployeePersonal = lazy(() =>
  import("../pages/attendance-management/employee/addemployee/personal")
);
const LazyEmployeeJobInfo = lazy(() =>
  import("../pages/attendance-management/employee/addemployee/job")
);
const LazyEmployeeBankInfo = lazy(() =>
  import("../pages/attendance-management/employee/addemployee/bank")
);
const LazyProfile = lazy(() =>
  import("../pages/attendance-management/view-profile")
);

// User
const LazyUser = lazy(() => import("../pages/attendance-management/user"));
const LazyViewUser = lazy(() =>
  import("../pages/attendance-management/user/view-user")
);
export const LazyAddUser = lazy(() =>
  import("../pages/attendance-management/user/add-user")
);

//Attendance
const LazyAttendance = lazy(() =>
  import("../pages/attendance-management/attendance-sheet")
);
//Shift Roaster
const LazyShiftRoaster = lazy(() =>
  import("../pages/attendance-management/shift-roasters")
);

//Assign Managment
const LazyAssignments = lazy(() =>
  import("../pages/attendance-management/assignments")
);

const LazyNotFound = lazy(() => import("../components/not-found"));

const LazyShiftRule = lazy(() =>
  import("../pages/attendance-management/shift-rule")
);
const LazyGetShiftRule = lazy(() =>
  import("../pages/attendance-management/shift-rule/shift-rule-list")
);
export const LazyShiftRuleTabData = lazy(() =>
  import(
    "../pages/attendance-management/shift-rule/add-shift-rule/shiftRuleTabs"
  )
);
export const LazyShiftRuleShift = lazy(() =>
  import("../pages/attendance-management/shift-rule/add-shift-rule/shift")
);
const LazyShiftRuleBreak = lazy(() =>
  import("../pages/attendance-management/shift-rule/add-shift-rule/break")
);
const LazyShiftRuleWeekOff = lazy(() =>
  import("../pages/attendance-management/shift-rule/add-shift-rule/week-off")
);
const LazyShiftRuleSetting = lazy(() =>
  import(
    "../pages/attendance-management/shift-rule/add-shift-rule/rule-setting"
  )
);
const LazyShiftRuleOverTime = lazy(() =>
  import("../pages/attendance-management/shift-rule/add-shift-rule/over-time")
);

const LazyLeavePlan = lazy(() =>
  import("../pages/attendance-management/leave-rule/plan")
);
const LazyViewLeavePlan = lazy(() =>
  import("../pages/attendance-management/leave-rule/plan/get-leave-plan")
);
export const LazyAddLeavePlan = lazy(() =>
  import("../pages/attendance-management/leave-rule/plan/add-leave-plan")
);
// Remarks
const LazyEmployeeFeedback = lazy(() =>
  import("../pages/attendance-management/employee-feedback")
);
// Staffings
const LazyStaffing = lazy(() => import("../pages/staffing"));
const LazyClient = lazy(() => import("../pages/staffing/client"));
const LazyViewClients = lazy(() =>
  import("../pages/staffing/client/view-client")
);
export const LazyAddClients = lazy(() =>
  import("../pages/staffing/client/add-client")
);
const LazyRequirement = lazy(() => import("../pages/staffing/job-openings"));
const LazyViewRequirement = lazy(() =>
  import("../pages/staffing/job-openings/view-requirements")
);
export const LazyAddRequirement = lazy(() =>
  import("../pages/staffing/job-openings/add-requirements")
);

// assets-management
const LazyAsset = lazy(() => import("../pages/asset-management"));
const LazyVander = lazy(() =>
  import("../pages/asset-management/vendor-profile")
);
const LazyAssetType = lazy(() =>
  import("../pages/asset-management/asset-type")
);
const LazyAssetManage = lazy(() =>
  import("../pages/asset-management/assets/get-assets")
);

const LazyAssetApplication = lazy(() =>
  import("../pages/asset-management/asset-application")
);

const LazyMyAssets = lazy(() => import("../pages/asset-management/my-assets"));
const LazyApprovalGroup = lazy(() =>
  import("../pages/asset-management/approval-group/getApproval")
);
const LazyApprovalAssignment = lazy(() =>
  import("../pages/asset-management/approval-assignment")
);
// Ticket management

const LazyTicketManagement = lazy(() => import("../pages/ticket-management"));
const LazyHelpDeskRule = lazy(() =>
  import("../pages/ticket-management/help-desk-rule")
);
const LazyHelpDeskTeam = lazy(() =>
  import("../pages/ticket-management/helpdesk-team")
);


const LazyMyTickets = lazy(() => import("../pages/ticket-management/my-tickets"));
const LazyTickets = lazy(() =>
  import("../pages/ticket-management/tickets")
);

// expense management
const LazyExpense = lazy(() => import("../pages/expense-management"));
const LazyVehicleMaster = lazy(() =>
  import("../pages/expense-management/vehicle-master")
);
const LazyExpenseCategory = lazy(() =>
  import("../pages/expense-management/expense-category")
);
const LazyExpenseManagement = lazy(() =>
  import("../pages/expense-management/expense-management")
);

//loan managemant
const LazyLoan = lazy(() => import("../pages/loan-management"));
const LazyLoanMasterTab = lazy(() =>
  import("../pages/loan-management/loan-master/loanMasterTabData")
);
const LazyLoanApplicationTab = lazy(() =>
  import("../pages/loan-management/loan-application/loanApplicationTabData")
);

const LazyLoanRecordLoanTab = lazy(() =>
  import("../pages/loan-management/record-loan/index")
);

// Applications
const LazyApplications = lazy(() =>
  import("../pages/attendance-management/applications")
);
const LazyGetApplications = lazy(() =>
  import("../pages/attendance-management/applications/get-applications")
);
export const routData = [
  {
    path: "*",
    element: <LazyNotFound />,
  },
  {
    path: "/",
    element: <LazyLandingPage />,
  },
  {
    path: "/sign-up",
    element: <LazySignUp />,
  },
  {
    path: "/sign-in",
    element: <LazySignIn />,
  },
  {
    path: "forgot-password",
    element: <LazyForgotPassword />,
  },
  {
    path: "reset-password",
    element: <LazyResetPassword />,
  },
  {
    path: "link-expired",
    element: <LazyExpiredResetPasswordLink />,
  },
  {
    path: "pagenotFound",
    element: <LazyPageNotFound />,
  },
  {
    path: "add-offer",
    element: <lazyLoad.LazyAddOffer />,
  },
  {
    path: "success",
    element: <lazyLoad.LazySuccessLayout />,
  },
  {
    path: "offerlink-expired",
    element: <lazyLoad.LazyOfferexpiredLink />,
  },
  {
    path: "organization",
    element: <lazyLoad.LazyOrganization />,
    children: [
      {
        path: "sign-up",
        element: <lazyLoad.LazyCrateOrganization />,
      },
      {
        path: "email-verification",
        element: <lazyLoad.LazyEmailVerification />,
      },
    ],
  },
  {
    path: "/",
    element: (
      <LazyAuthWrapper>
        <LazyMainLayout />
      </LazyAuthWrapper>
    ),
    children: [
      // Attedance Management
      {
        path: "attendance-management",
        element: <LazyAttendanceManagement />,
        children: [
          {
            path: "dashboard",
            element: <LazyDashboard />,
            location: "/attendance-management/dashboard",
          },
          {
            path: "masters",
            element: <lazyLoad.LazyMasterTabs />,
            children: [
              {
                path: "area",
                element: <lazyLoad.LazyArea />,
                header: {
                  title: "Masters",
                },
                location: "/attendance-management/masters/area",
              },
              {
                path: "unit",
                element: <lazyLoad.LazyUnit />,
                header: {
                  title: "Masters",
                },
                location: "/attendance-management/masters/unit",
              },
              {
                path: "department",
                element: <lazyLoad.LazyDepartment />,
                header: {
                  title: "Masters",
                },
                location: "/attendance-management/masters/department",
              },
              {
                path: "designation",
                element: <lazyLoad.LazyDesignation />,
                header: {
                  title: "Masters",
                },
                location: "/attendance-management/masters/designation",
              },
            ],
          },
          {
            path: "role-permission",
            element: <LazyRolesPermissions />,
            children: [
              {
                path: "",
                element: <LazyRolesPermissionsList />,
                header: {
                  title: "Role & Permission",
                },
                location: "/attendance-management/role-permission",
              },
              {
                path: "add-role",
                element: <LazyAddRoles />,
                children: [],
                header: {
                  title: "Role & Permission",
                },
                location: "/attendance-management/role-permission/add-role",
              },
              {
                path: "*",
                element: <LazyNotFound />,
              },
            ],
            header: "Role & Permission",
            location: "/attendance-management/role-permission",
          },
          {
            path: "employee",
            element: <LazyEmployee />,
            children: [
              {
                path: "",
                element: <LazyGetEmployee />,
                header: {
                  title: "Employee",
                },
                location: "/attendance-management/employee",
              },
              {
                path: "addemployee",
                element: <lazyLoad.LazyAdd />,
                children: [
                  {
                    path: "",
                    element: <LazyEmployeePersonal />,
                    header: {
                      title: "Employee",
                    },
                    location: "/attendance-management/employee/addemployee",
                  },
                  {
                    path: "job-info",
                    element: <LazyEmployeeJobInfo />,
                    header: {
                      title: "Employee",
                    },
                    location:
                      "/attendance-management/employee/addemployee/job-info",
                  },
                  {
                    path: "bank-info",
                    element: <LazyEmployeeBankInfo />,
                    header: {
                      title: "Employee",
                    },
                    location:
                      "/attendance-management/employee/addemployee/bank-info",
                  },
                ],
                header: "Employee",
                location: "/attendance-management/employee/addemployee",
              },
              {
                path: "*",
                element: <LazyNotFound />,
              },
            ],
            header: "Employee",
            location: "/attendance-management/employee",
          },
          {
            path: "employee-feedback",
            element: <LazyEmployeeFeedback />,
            header: {
              title: "Employee Feedback",
            },
            location: "/attendance-management/employee-feedback",
          },
          {
            path: "profile/*",
            element: <LazyProfile />,
            title: "View User Details",
            location: "/attendance-management/profile",
            children: [
              {
                path: "personal/:id",
                element: <LazyProfilePersonal />,
                header: {
                  title: "View User Details",
                },
                location: "/attendance-management/profile/personal/:id",
              },
              {
                path: "profilejob/:id",
                element: <LazyProfileJob />,
                header: {
                  title: "View User Details",
                },
                location: "/attendance-management/profile/profilejob/:id",
              },
              {
                path: "profilebank/:id",
                element: <LazyProfileBank />,
                header: {
                  title: "View User Details",
                },
                location: "/attendance-management/profile/profilebank/:id",
              },
              {
                path: "*",
                element: <LazyNotFound />,
              },
              {
                path: "personalDetails/:id",
                element: <LazyProfilePersonalDetails />,
                header: {
                  title: "View User Personal Details",
                },
                location: "/attendance-management/profile/personalDetails/:id",
              },
              {
                path: "profileAppraisal/:id",
                element: <LazyProfileAppraisal />,
                header: {
                  title: "View User Appraisal Details",
                },
                location: "/attendance-management/profile/profileAppraisal/:id",
              },
            ],
          },
          {
            path: "user",
            element: <LazyUser />,
            children: [
              {
                path: "",
                element: <LazyViewUser />,
                header: {
                  title: "Users",
                },
                location: "/attendance-management/user",
              },
              {
                path: "add-user",
                element: <LazyAddUser />,
                children: [],
                header: {
                  title: "Users",
                },
                location: "/attendance-management/user/add-user",
              },
              {
                path: "*",
                element: <LazyNotFound />,
              },
            ],
            header: "Users",
            location: "/attendance-management/user",
          },
          // Shift Rule
          {
            path: "shift-rule",
            element: <LazyShiftRule />,
            children: [
              {
                path: "",
                element: <LazyGetShiftRule />,
                header: {
                  title: "Shift Rule",
                },
                location: "/attendance-management/shift-rule",
              },
              {
                path: "add-shift-rule",
                element: <LazyShiftRuleTabData />,
                children: [
                  {
                    path: "shift",
                    element: <LazyShiftRuleShift />,
                    header: {
                      title: "Shift Rule",
                    },
                    location:
                      "/attendance-management/shift-rule/add-shift-rule",
                  },
                  {
                    path: "break",
                    element: <LazyShiftRuleBreak />,
                    header: {
                      title: "Shift Rule",
                    },
                    location:
                      "/attendance-management/shift-rule/add-shift-rule/break",
                  },
                  {
                    path: "over-time",
                    element: <LazyShiftRuleOverTime />,
                    header: {
                      title: "Shift Rule",
                    },
                    location:
                      "/attendance-management/shift-rule/add-shift-rule/over-time",
                  },
                  {
                    path: "week-off",
                    element: <LazyShiftRuleWeekOff />,
                    header: {
                      title: "Shift Rule",
                    },
                    location:
                      "/attendance-management/shift-rule/add-shift-rule/week-off",
                  },
                  {
                    path: "rule-setting",
                    element: <LazyShiftRuleSetting />,
                    header: {
                      title: "Shift Rule",
                    },
                    location:
                      "/attendance-management/shift-rule/add-shift-rule/rule-setting",
                  },
                ],
                location: "/attendance-management/shift-rule/add-shift-rule",
              },
              {
                path: "*",
                element: <LazyNotFound />,
              },
            ],
            location: "/attendance-management/shift-rule",
          },
          // Attedance Rule
          {
            path: "attedance-rule/*",
            element: <lazyLoad.LazyAttedanceRule />,
            children: [
              {
                path: "network",
                element: <lazyLoad.LazyAttedanceIPNetwork />,
                header: {
                  title: "Attedance Rule",
                },
                location: "/attendance-management/attedance-rule/network",
              },
              {
                path: "location",
                element: <lazyLoad.LazyAttedanceLocation />,
                header: {
                  title: "Attedance Rule",
                },
                location: "/attendance-management/attedance-rule/location",
              },
              {
                path: "capturing-rule",
                element: <lazyLoad.LazyAttedanceCapturingRule />,
                header: {
                  title: "Attedance Rule",
                },
                location:
                  "/attendance-management/attedance-rule/capturing-rule",
              },
            ],
            location: "/attendance-management/attedance-rule",
          },
          {
            path: "assignments/*",
            element: <LazyAssignments />,
            header: {
              title: "Rule Assignments",
            },
            location: "/attendance-management/assignments",
          },

          {
            path: "holiday-rule",
            element: <lazyLoad.LazyHolidayRuleTabs />,
            children: [
              {
                path: "holiday",
                element: <lazyLoad.LazyHoliday />,
                header: {
                  title: `Holiday Rule (${new Date().getFullYear()})`,
                },
                location: "/attendance-management/holiday-rule/holiday",
              },
              {
                path: "holiday-group",
                element: <lazyLoad.LazyHolidayGroup />,
                header: {
                  title: `Holiday Rule ${new Date().getFullYear()}`,
                },
                location: "/attendance-management/holiday-rule/holiday-group",
              },
            ],
          },
          {
            path: "attendance",
            element: <LazyAttendance />,
            header: {
              title: "Attendance Sheet",
            },
            location: "/attendance-management/attendance",
          },
          {
            path: "shift-roaster",
            element: <LazyShiftRoaster />,
            header: {
              title: "Shift Roaster",
            },
            location: "/attendance-management/shift-roaster",
          },

          {
            path: "leave-rule",
            element: <lazyLoad.LazyLeaveRuleTabsTabs />,
            children: [
              {
                path: "type",
                element: <lazyLoad.LazyType />,
                header: {
                  title: "Leave Rule",
                },
                location: "/attendance-management/leave-rule/type",
              },
              {
                path: "plan",
                element: <LazyLeavePlan />,
                children: [
                  {
                    path: "",
                    element: <LazyViewLeavePlan />,
                    header: {
                      title: "Leave Rule",
                    },
                    location: "/attendance-management/leave-rule/plan",
                  },
                  {
                    path: "add-plan",
                    element: <LazyAddLeavePlan />,
                    children: [],
                    header: {
                      title: "Leave Rule",
                    },
                    location: "/attendance-management/leave-rule/add-plan",
                  },
                  {
                    path: "*",
                    element: <LazyNotFound />,
                  },
                ],
                location: "/attendance-management/leave-rule",
              },

              {
                path: "group",
                element: <lazyLoad.LazyGroup />,
                header: {
                  title: "Leave Rule",
                },
                location: "/attendance-management/leave-rule/group",
              },
            ],
          },
          {
            path: "employee",
            element: <LazyEmployee />,
            children: [
              {
                path: "",
                element: <LazyGetEmployee />,
                header: {
                  title: "Employee",
                },
                location: "/attendance-management/employee",
              },
              {
                path: "addemployee",
                element: <lazyLoad.LazyAdd />,
                children: [
                  {
                    path: "",
                    element: <LazyEmployeePersonal />,
                    header: {
                      title: "Employee",
                    },
                    location: "/attendance-management/employee/addemployee",
                  },
                  {
                    path: "job-info",
                    element: <LazyEmployeeJobInfo />,
                    header: {
                      title: "Employee",
                    },
                    location:
                      "/attendance-management/employee/addemployee/job-info",
                  },
                  {
                    path: "bank-info",
                    element: <LazyEmployeeBankInfo />,
                    header: {
                      title: "Employee",
                    },
                    location:
                      "/attendance-management/employee/addemployee/bank-info",
                  },
                ],
                header: "Employee",
                location: "/attendance-management/employee/addemployee",
              },
              {
                path: "*",
                element: <LazyNotFound />,
              },
            ],
            header: "Employee",
            location: "/attendance-management/employee",
          },
          {
            path: "approvals/*",
            element: <LazyApprovals />,
            children: [
              {
                path: "",
                element: <LazyGetApprovals />,
                header: {
                  title: "Approval Rule",
                },
                location: "/attendance-management/approvals",
              },
              {
                path: "*",
                element: <LazyNotFound />,
              },
            ],
            title: "Approval Rule",
            location: "/attendance-management/approvals",
          },
          {
            path: "applications/*",
            element: <LazyApplications />,
            location: "/attendance-management/applications",
            children: [
              {
                path: "",
                element: <LazyGetApplications />,
                header: {
                  title: "Applications",
                },
                location: "/attendance-management/applications",
              },
              {
                path: "*",
                element: <LazyNotFound />,
              },
            ],
          },

          {
            path: "hr-handbook",
            element: <lazyLoad.LazyHRHandbookTabs />,
            children: [
              {
                path: "handbook",
                element: <lazyLoad.LazyHandbook />,
                header: {
                  title: "HR Handbook",
                },
                location: "/attendance-management/hr-handbook/handbook",
              },
              {
                path: "hr-policy",
                element: <lazyLoad.LazyPolicy />,
                header: {
                  title: "HR Handbook",
                },
                location: "/attendance-management/hr-handbook/hr-policy",
              },
            ],
            location: "/attendance-management/hr-handbook",
          },
        ],
      },
      // Payroll
      {
        path: "payroll",
        element: <lazyLoad.LazyPayRoll />,
        children: [
          {
            path: "user-details",
            element: <lazyLoad.LazyUserDetails />,
            children: [
              {
                path: "",
                element: <lazyLoad.LazyViewUserDetails />,
                header: {
                  title: "User Details",
                },
                location: "/payroll/user-details",
              },
              {
                path: "add-user-details",
                element: <lazyLoad.LazyAddUserDetails />,
                children: [],
                header: {
                  title: "User Details",
                },
                location: "/payroll/user-details/add-user-details",
              },
              {
                path: "*",
                element: <LazyNotFound />,
              },
            ],
            header: "User Details",
            location: "/payroll/user-details",
          },
          {
            path: "payroll",
            element: <lazyLoad.LazyGetPayRoll />,
            header: {
              title: "Payroll",
            },
            location: "/payroll/payroll",
          },
          {
            path: "salary-component",
            element: <lazyLoad.LazySalaryComponent />,
            header: {
              title: "Salary Component",
            },
            location: "/payroll/salary-component",
          },
          {
            path: "statutory",
            element: <lazyLoad.LazyStatutoryComponent />,
            header: {
              title: "Statutory Component",
            },
            location: "/payroll/statutory",
          },
          {
            path: "salary-slip",
            element: <lazyLoad.LazySalarySlip />,
            header: {
              title: "Salary Slip",
            },

            location: "/payroll/salary-slip",
          },
        ],
        // location: "/payroll/payroll",
      },
      //Asset Management
      {
        path: "asset-management",
        element: <LazyAsset />,
        children: [
          {
            path: "vendor/*",
            element: <LazyVander />,
            header: {
              title: "Vendor",
            },
            location: "/asset-management/vendor",
          },
          {
            path: "type/*",
            element: <LazyAssetType />,
            header: {
              title: "Asset Type",
            },
            location: "/asset-management/type",
          },
          {
            path: "assets/*",
            element: <LazyAssetManage />,
            header: {
              title: "Assets",
            },
            location: "/asset-management/assets",
          },
          {
            path: "application/*",
            element: <LazyAssetApplication />,
            header: {
              title: "Asset Management",
            },
            location: "/asset-management/application",
          },

          {
            path: "my-assets",
            element: <LazyMyAssets />,
            header: {
              title: " My Assets",
            },
            location: "/asset-management/my-assets",
          },
          {
            path: "group",
            element: <LazyApprovalGroup />,
            header: {
              title: "Approval Group",
            },
            location: "/asset-management/group",
          },
          {
            path: "assignment",
            element: <LazyApprovalAssignment />,
            header: {
              title: "Approval Assignment",
            },
            location: "/asset-management/assignment",
          },
        ],
      },
      // Ticket Management
      {
        path: "ticket-management",
        element: <LazyTicketManagement />,
        children: [
          {
            path: "help-desk-rule/*",
            element: <LazyHelpDeskRule />,
            header: {
              title: "Help Desk Rule",
            },
            location: "/ticket-management/help-desk-rule",
          },
          {
            path: "tickets/*",
            element: <LazyTickets />,
            header: {
              title: "Tickets",
            },
            location: "/ticket-management/tickets",
          },
          {
            path: "my-tickets",
            element: <LazyMyTickets />,
            header: {
              title: " My Tickets",
            },
            location: "/ticket-management/my-tickets",
          },
          {
            path: "helpdesk-team",
            element: <LazyHelpDeskTeam />,
            header: {
              title: "Helpdesk Team",
            },
            location: "/ticket-management/helpdesk-team",
          },
          
        ],
      },

      // Staffing Module
      {
        path: "staffing",
        element: <LazyStaffing />,
        children: [
          {
            path: "",
            element: <LazyRequirement />,
            children: [
              {
                path: "job-openings",
                element: <LazyRequirement />,
                children: [
                  {
                    path: "",
                    element: <LazyViewRequirement />,
                    header: {
                      title: "Requirement",
                      path: [
                        {
                          title: "Home",
                          navigate: null,
                        },
                        {
                          title: "Staffing",
                          navigate: null,
                        },
                        {
                          title: "Job Openings",
                          navigate: null,
                        },
                        {
                          title: "View Requirement",
                          navigate: null,
                        },
                      ],
                    },

                    location: "/staffing/job-openings",
                  },
                  {
                    path: "add-requirements",
                    element: <LazyAddRequirement />,
                    header: {
                      title: "Requirements",
                      path: [
                        {
                          title: "Home",
                          navigate: null,
                        },
                        {
                          title: "Staffing",
                          navigate: null,
                        },
                        {
                          title: "Job Openings",
                          navigate: null,
                        },
                        {
                          title: "Add Requirement",
                          navigate: null,
                        },
                      ],
                    },
                    location: "/staffing/job-openings/add-requirements",
                  },
                  {
                    path: "",
                    element: <lazyLoad.LazyJobOpeningProcess />,
                    children: [
                      {
                        path: "upload-resume/:id",
                        element: <lazyLoad.LazyUploadResume />,
                        header: {
                          title: "Requirements",
                          path: [
                            {
                              title: "Home",
                              navigate: null,
                            },
                            {
                              title: "Staffing",
                              navigate: null,
                            },
                            {
                              title: "Job Openings",
                              navigate: null,
                            },
                            {
                              title: "Upload Resume",
                              navigate: null,
                            },
                          ],
                        },
                        children: [
                          {
                            path: "",
                            element: <lazyLoad.LazyViewUploadedResume />,
                            header: {
                              title: "Requirements",
                              path: [
                                {
                                  title: "Home",
                                  navigate: null,
                                },
                                {
                                  title: "Staffing",
                                  navigate: null,
                                },
                                {
                                  title: "Job Openings",
                                  navigate: null,
                                },
                                {
                                  title: "Upload Resume",
                                  navigate: null,
                                },
                              ],
                            },
                            location:
                              "/staffing/job-openings/upload-resume/:id",
                          },
                          {
                            path: "add-closing-block",
                            element: <lazyLoad.LazyAddClosingBlock />,
                            header: {
                              title: "Requirements",
                              path: [
                                {
                                  title: "Home",
                                  navigate: null,
                                },
                                {
                                  title: "Staffing",
                                  navigate: null,
                                },
                                {
                                  title: "Job Openings",
                                  navigate: null,
                                },
                                {
                                  title: "Upload Resume",
                                  navigate: null,
                                },
                                {
                                  title: "Add Closing Block",
                                  navigate: null,
                                },
                              ],
                            },
                            location:
                              "/staffing/job-openings/upload-resume/:id/add-closing-block",
                          },
                        ],
                        location: "/staffing/job-openings/upload-resume/:id",
                      },
                      {
                        path: "set-interview/:id",
                        element: <lazyLoad.LazySetInterView />,
                        header: {
                          title: "Requirements",
                          path: [
                            {
                              title: "Home",
                              navigate: null,
                            },
                            {
                              title: "Staffing",
                              navigate: null,
                            },
                            {
                              title: "Job Openings",
                              navigate: null,
                            },
                            {
                              title: "Set Interview",
                              navigate: null,
                            },
                          ],
                        },
                        location: "/staffing/job-openings/set-interview/:id",
                      },
                      {
                        path: "job-offer/:id",
                        element: <lazyLoad.LazyJobOffer />,
                        header: {
                          title: "Requirements",
                         
                        },
                        location: "/staffing/job-openings/job-offer/:id",
                        children: [
                          {
                            path: "",
                            element: <lazyLoad.LazyJobOfferList />,
                            location: "/staffing/job-openings/job-offer/:id",
                          },
                          {
                            path: "edit-offer",
                            element: <lazyLoad.LazyAddOffer />,
                            location:
                              "/staffing/job-openings/job-offer/:id/edit-offer",
                          },
                        ],
                      },
                    ],
                    header: "Requirements",
                    location: "/staffing/job-openings/upload-resume",
                  },
                ],
                header: {
                  title: "Requirements",
                 
                },
                location: "/staffing/job-openings",
              },
            ],
            header: "Requirements",
            location: "/staffing/job-openings",
          },
          {
            path: "team/*",
            element: <lazyLoad.LazyTeam />,

            ttitle: "Team",

            header: {
              title: "Team",
             
            },
            location: "/staffing/team",
          },
          {
            path: "client",
            element: <LazyClient />,
            children: [
              {
                path: "",
                element: <LazyViewClients />,
                header: {
                  title: "Client",
               
                },
                location: "/staffing/client",
              },
              {
                path: "add-client",
                element: <LazyAddClients />,
                children: [],
                header: {
                  title: "Client",
                
                },
                location: "/staffing/client/add-client",
              },
              {
                path: "*",
                element: <LazyNotFound />,
              },
            ],
            header: "Clients",
            location: "/staffing/client",
          },
          {
            path: "activity",
            element: <lazyLoad.LazyActivityTabs />,
            children: [
              {
                path: "target-submission",
                element: <lazyLoad.LazyTargetSubmission />,
                header: {
                  title: "Activity",
                  
                },
                location: "/staffing/activity/target-submission",
              },
              {
                path: "offer-hire",
                element: <lazyLoad.LazyOfferHire />,
                header: {
                  title: "Activity",
              
                },
                location: "/staffing/activity/offer-hire",
              },
              {
                path: "search-resume",
                element: <lazyLoad.LazySearchResume />,
                header: {
                  title: "Activity",
               
                },
                location: "/staffing/activity/search-resume",
              },
            ],
            header: "Activity",
            location: "/staffing/activity/target-submission",
          },
        ],
      },
      // Apprasial Module
      {
        path: "appraisal/*",
        element: <lazyLoad.LazyAppraisal />,
        children: [
          {
            path: "appraisal-list/*",
            element: <lazyLoad.LazyAppraisalList />,
            children: [
              {
                path: "",
                element: <lazyLoad.LazyGetAppraisalList />,
                header: {
                  title: "Appraisal",
                  path: [
                    {
                      title: "Home",
                      navigate: null,
                    },
                    {
                      title: "Appraisal",
                      navigate: null,
                    },
                  ],
                },
                location: "/appraisal/appraisal-list",
              },
              {
                path: "view-appraisal",
                element: <lazyLoad.LazyViewAppraisal />,
                header: {
                  title: "Appraisal",
                  path: [
                    {
                      title: "Home",
                      navigate: null,
                    },
                    {
                      title: "Appraisal",
                      navigate: null,
                    },
                  ],
                },
                location: "/appraisal/appraisal-list/view-appraisal",
              },
              {
                path: "rating-form-appraisal",
                element: <lazyLoad.LazyFormAppraisal />,
                header: {
                  title: "Appraisal",
                  path: [
                    {
                      title: "Home",
                      navigate: null,
                    },
                    {
                      title: "Appraisal",
                      navigate: null,
                    },
                  ],
                },
                location: "/appraisal/appraisal-list/view-appraisal",

                children: [
                  {
                    path: "",
                    element: <lazyLoad.LazykeyPerfomingIndicators />,
                    header: {
                      title: "Appraisal",
                      path: [
                        {
                          title: "Home",
                          navigate: null,
                        },
                        {
                          title: "Appraisal",
                          navigate: null,
                        },
                        {
                          title: "Key Performing Indicators",
                          navigate: null,
                        },
                      ],
                    },
                    location: "/appraisal/appraisal-list/rating-form-appraisal",
                  },
                  {
                    path: "feedBack-comments",
                    element: <lazyLoad.LazyFeedBackComments />,
                    header: {
                      title: "Appraisal",
                      path: [
                        {
                          title: "Home",
                          navigate: null,
                        },
                        {
                          title: "Appraisal",
                          navigate: null,
                        },
                        {
                          title: "Feedback & Comments",
                          navigate: null,
                        },
                      ],
                    },
                    location:
                      "/appraisal/appraisal-list/rating-form-appraisal/feedback-comments",
                  },
                ],
              },
              {
                path: "view-rating-appraisal",
                element: <lazyLoad.LazyViewRatingAppraisal />,
                header: {
                  title: "Appraisal",
                  path: [
                    {
                      title: "Home",
                      navigate: null,
                    },
                    {
                      title: "Appraisal",
                      navigate: null,
                    },
                  ],
                },

                location: "/appraisal/appraisal-list/view-rating-appraisal",
              },
            ],
            header: "Appraisal",
            location: "/appraisal/appraisal-list",
          },
        ],
        header: "Appraisal",
        location: "/appraisal",
      },

      //Document
      {
        path: "document",
        element: <lazyLoad.LazyDocuments />,
        children: [
          {
            path: "confirmation-letter",
            element: <lazyLoad.LazyConfirmationLetter />,
            header: {
              title: "Confirmation Letter",
              path: [
                {
                  title: "Home",
                  navigate: null,
                },
                {
                  title: "Document",
                  navigate: null,
                },
                {
                  title: "Confirmation Letter",
                  navigate: null,
                },
              ],
            },
            location: "/document/confirmation-letter",
          },
          {
            path: "increment-letter",
            element: <lazyLoad.LazyIncrementLetter />,
            header: {
              title: "Increment Letter",
              path: [
                {
                  title: "Home",
                  navigate: null,
                },
                {
                  title: "Document",
                  navigate: null,
                },
                {
                  title: "Increment Letter",
                  navigate: null,
                },
              ],
            },
            location: "/document/increment-letter",
          },
          {
            path: "relieving-experience-letter",
            element: <lazyLoad.LazyRelievingExperienceLetter />,
            header: {
              title: "Relieving & Experience Letter",
              path: [
                {
                  title: "Home",
                  navigate: null,
                },
                {
                  title: "Document",
                  navigate: null,
                },
                {
                  title: "Relieving & Experience Letter",
                  navigate: null,
                },
              ],
            },
            location: "/document/relieving-experience-letter",
          },
        ],
        header: "Document",
        location: "/document/confirmation-letter",
      },

      // Plan
      {
        path: "plan",
        element: <LazyPlan />,
        children: [
          {
            path: "organization-profile",
            element: <LazyOrganizationProfile />,
            header: {
              title: "Organization Profile",
              path: [
                {
                  title: "Home",
                  navigate: null,
                },
                {
                  title: "Plan",
                  navigate: null,
                },
                {
                  title: "Organization Profile",
                  navigate: null,
                },
              ],
            },

            location: "/plan/organization-profile",
          },
          {
            path: "view-plan",
            element: <LazyViewPlan />,
            header: {
              title: "Plan",
            },
            location: "/plan/view-plan",
          },
          {
            path: "super-admin-plan",
            element: <LazyPlanTabs />,
            children: [
              {
                path: "plan-list",
                element: <LazyPlanList />,
                header: {
                  title: "Plan",
                },
                location: "/plan/super-admin-plan/plan-list",
              },
              {
                path: "organization",
                element: <LazyOrganizationPlans />,
                header: {
                  title: "Organization",
                },
                location: "/plan/super-admin-plan/organization",
              },
            ],

            location: "/plan/super-admin-plan/plan-list",
          },
        ],
      },
      // {
      //   path: "/change-password",
      //   element: <LazyChangePassword />,
      //   header: {
      //     title: "Change Password",
      //   },
      //   location: "/change-password",
      // },

      // expense
      {
        path: "expense-management",
        element: <LazyExpense />,
        children: [
          {
            path: "vehicle-master/*",
            element: <LazyVehicleMaster />,
            header: {
              title: "Vehicle Master",
              path: [
                {
                  title: "Home",
                  navigate: null,
                },
                {
                  title: "Expense Management",
                  navigate: null,
                },
                {
                  title: "Vehicle Master",
                  navigate: null,
                },
              ],
            },
            location: "/expense-management/vehicle-master",
          },
          {
            path: "expense-category/*",
            element: <LazyExpenseCategory />,
            header: {
              title: "Expense Category",
              path: [
                {
                  title: "Home",
                  navigate: null,
                },
                {
                  title: "Expense Management",
                  navigate: null,
                },
                {
                  title: "Expense Category",
                  navigate: null,
                },
              ],
            },
            location: "/expense-management/expense-category",
          },

          {
            path: "expense-management",
            element: <LazyExpenseManagement />,
            header: {
              title: "Expense Management",
              path: [
                {
                  title: "Home",
                  navigate: null,
                },
                {
                  title: "Expense Management",
                  navigate: null,
                },
                {
                  title: "Expense Management",
                  navigate: null,
                },
              ],
            },
            location: "/expense-management/expense-management",
          },
        ],
      },

      //loan
      {
        path: "loan-management/*",
        element: <LazyLoan />,
        children: [
          {
            path: "loan-application/*",
            element: <LazyLoanApplicationTab />,
            children: [
              {
                path: "application",
                element: <lazyLoad.LazyApplication />,
                header: {
                  title: "Apply Loan",
                  path: [
                    {
                      title: "Home",
                      navigate: null,
                    },
                    {
                      title: "Loan Management",
                      navigate: null,
                    },
                    {
                      title: "Loan Application",
                      navigate: null,
                    },
                    {
                      title: "Application",
                      navigate: null,
                    },
                  ],
                },
                location: "/loan-management/loan-application/application",
              },
              {
                path: "my-loan",
                element: <lazyLoad.LazyMyLoan />,
                header: {
                  title: "Apply Loan",
                  path: [
                    {
                      title: "Home",
                      navigate: null,
                    },
                    {
                      title: "Loan Management",
                      navigate: null,
                    },
                    {
                      title: "Loan Application",
                      navigate: null,
                    },
                    {
                      title: "My Loan",
                      navigate: null,
                    },
                  ],
                },
                location: "/loan-management/loan-application/my-loan",
              },
            ],

            location: "/loan-management/loan-application",
          },
          {
            path: "loan-master/*",
            element: <LazyLoanMasterTab />,
            children: [
              {
                path: "loan-type",
                element: <lazyLoad.LazyLoanMasterType />,
                header: {
                  title: "Loan Master",
                  path: [
                    {
                      title: "Home",
                      navigate: null,
                    },
                    {
                      title: "Loan Management",
                      navigate: null,
                    },
                    {
                      title: "Loan Master",
                      navigate: null,
                    },
                    {
                      title: "Loan Type",
                      navigate: null,
                    },
                  ],
                },
                location: "/loan-management/loan-master/loan-type",
              },
              {
                path: "repayment-mode",
                element: <lazyLoad.LazyLoanMasterRepayment />,
                header: {
                  title: "Loan Master",
                  path: [
                    {
                      title: "Home",
                      navigate: null,
                    },
                    {
                      title: "Loan Management",
                      navigate: null,
                    },
                    {
                      title: "Loan Master",
                      navigate: null,
                    },
                    {
                      title: "Repayment Mode",
                      navigate: null,
                    },
                  ],
                },
                location: "/loan-management/loan-master/repayment-mode",
              },
            ],

            location: "/loan-management/loan-master",
          },

          {
            path: "record-loan/*",
            element: <LazyLoanRecordLoanTab />,
            children: [
              {
                path: "record-list",
                element: <lazyLoad.LazyRecordList />,
                header: {
                  title: "Record Loan",
                  path: [
                    {
                      title: "Home",
                      navigate: null,
                    },
                    {
                      title: "Loan Management",
                      navigate: null,
                    },
                    {
                      title: "Record Loan",
                      navigate: null,
                    },
                    {
                      title: "Record List",
                      navigate: null,
                    },
                  ],
                },
                location: "/loan-management/record-loan/record-list",
              },
              {
                path: "add-record",
                element: <lazyLoad.LazyAddRecord />,
                header: {
                  title: "Add Record",
                  path: [
                    {
                      title: "Home",
                      navigate: null,
                    },
                    {
                      title: "Loan Management",
                      navigate: null,
                    },
                    {
                      title: "Record Loan",
                      navigate: null,
                    },
                    {
                      title: "Add Record",
                      navigate: null,
                    },
                  ],
                },
                location: "/loan-management/record-loan/add-record",
              },
            ],
            location: "/loan-management/record-loan",
          },
        ],
      },
      {
        path: "*",
        element: <LazyNotFound />,
      },
    ],
  },
];
export const routeObject = createBrowserRouter(routData);
