/* eslint-disable import/namespace */
import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  assetsList: [],
};

const assetsSlice = createSlice({
  name: "assets",
  initialState,
  reducers: {
    setAssetsList: (state, action) => {
      state.assetsList = action.payload;
    },
  },
});
export const { setAssetsList } = assetsSlice.actions;
export default assetsSlice.reducer;
